import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ArtistPageTemplate = ({ data: { post, fires } }) => {
    var agenda=fires.edges
  const featuredImage = {
    //data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    //alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Seo title={`▶️ ${post.name}: Agenda de Fires i Festes`} description={`Agenda de fires i festes per ${post.name}. Dates i Horaris, explicació de tots els esdeveniments, agenda actualitzada 2023.`} />
    <Helmet>
        <script src={'/menu.js'} />
        <link rel="icon" href={'/ico32.jpg'} sizes="32x32" />
        <link rel="icon" href={'/ico192.jpg'} sizes="192x192" />
        <link rel="apple-touch-icon" href={'/ico180.jpg'} />
        <meta name="msapplication-TileImage" content={'/ico270.jpg'} />
    </Helmet>
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >

      <div class="jumbotron p-4 p-md-5 text-black rounded bg-light row">
        <div class="col-xs-12 col-md-8 px-0">
          <div class="h1 display-4 font-italic">{parse(post.name)}</div>
          <p class="lead mb-3">Agenda de Fires i Festes</p>
          <p class="lead my-4"><Link to={`/comarca/${post.comarca}/`}><small>Tornar a la comarca</small></Link></p>
        </div>
        <div className="col-xs-12 col-md-4">
          <img class="card-img-top" src={`/imgs/poblacions/${post.slug}.jpg`} alt={post.name} />
        </div>
      </div>

        {!!post.intro && (
        <section className="mt-3">
            <div></div>
        </section>
        )}
        <h1 class="h2 mt-4 mt-2">Agenda de Fires i festes a {parse(post.name)}</h1>
        {agenda.map(node => (
          <div className="mb-4" key={node.artist.id}>
            <div className="card">
              <div className="row">
                <div className="col-xs-12 col-md-4">
                <img class="card-img-top" src={node.artist.img !== "" ? node.artist.img : `/imgs/poblacions/${node.artist.municipioslug}.jpg`} alt={node.artist.altimg} />
                </div>
                <div class="col-xs-12 col-md-8 card-body">
                  <Link to={`/esdeveniment/${node.artist.slug}/`}>
                  <h3 class="card-title">{node.artist.title}</h3>
                  </Link>
                  <p>
                    {(() => {
                      const fechaInicio = new Date(parseInt(node.artist.finicio));

                      if (!isNaN(fechaInicio.getTime())) {
                        const dia = fechaInicio.getDate().toString().padStart(2, '0');
                        const mes = (fechaInicio.getMonth() + 1).toString().padStart(2, '0');
                        const año = fechaInicio.getFullYear();

                        return `${dia}/${mes}/${año}`;
                      } else {
                        return '';
                      }
                      })()}&nbsp;-&nbsp;{(() => {
                      const fechaInicio = new Date(parseInt(node.artist.ffin));

                      if (!isNaN(fechaInicio.getTime())) {
                        const dia = fechaInicio.getDate().toString().padStart(2, '0');
                        const mes = (fechaInicio.getMonth() + 1).toString().padStart(2, '0');
                        const año = fechaInicio.getFullYear();

                        return `${dia}/${mes}/${año}`;
                      } else {
                        return '';
                      }
                    })()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        {!!post.descripcion && (
          <section itemProp="articleBody">
            <div dangerouslySetInnerHTML={{__html:post.descripcion}} />
          </section>
        )}
      </article>
    </Layout>
  )
}

export default ArtistPageTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!,
    $filter: String!,
  ) {
    post: mysqlPoblacions(id: { eq: $id }) {
      id
      name
      img
      descripcion
      comarca
      slug
    }
    fires: allMysqlFires(
      limit: 300
      filter: {finished: {eq: 0},municipio: {glob: $filter}}
      sort: {order: ASC, fields: ffin}
    ) {
      edges {
        artist: node {
          id
          slug
          title
          altimg
          img
          municipioslug
          finicio
          ffin
        }
      }
    }
  }
`
